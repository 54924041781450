import {finalize} from 'rxjs/operators'
import {DataSet} from '../models/DataSet'
import {Observable} from 'rxjs'
import {environment} from '../../../environments/environment'
import { HttpClient } from '@angular/common/http'
import {Injectable, EventEmitter} from '@angular/core'
import {Dir} from '../components/web/dir';

@Injectable({providedIn: "root"})
export class DirService {
    public endPoint: string
    public fileEndPoint: string
    dataChanged: EventEmitter<boolean> = new EventEmitter()

    constructor(private http: HttpClient) {
        this.endPoint = environment.restApiHost + 'v1/web/dir'
        this.fileEndPoint = this.endPoint + '/file'
    }

    /**
     * Perform emit of data changed
     * @emits dataChanged<boolean>
     */
    private dataChangedEmit() {
        this.dataChanged.emit(true)
    }

    /**
     * API request for list of dirs
     * @returns {Observable<DataSet>}
     */
    list(): Observable<DataSet<Dir>> {
        return this.http.get<DataSet<Dir>>(this.endPoint)
    }

    /**
     * API request for page details
     * @param {string} dirPath
     * @param excludeFiles
     * @returns {Observable<Dir>}
     */
    get(dirPath: string, excludeFiles = ''): Observable<Array<string>> {
        return this.http.get<Array<string>>(this.endPoint + '/files', {params: {path: dirPath, exclude: excludeFiles}})
    }

    /**
     * API request to update or insert dir
     * @param dirData
     * @returns {Observable<Dir>}
     */
    save(dirData: Dir): Observable<Dir> {
        return this.http.post<Dir>(this.endPoint, dirData).pipe(
            finalize(() => {
                this.dataChangedEmit()
            })
        )
    }

    /**
     * API request to delete dir
     * @param {string} dirPath
     * @returns {Observable<void>}
     */
    delete(dirPath: string): Observable<void> {
        return this.http.delete<void>(this.endPoint, {params: {path: dirPath}}).pipe(
            finalize(() => {
                this.dataChangedEmit()
            })
        )
    }

    /**
     * API request to delete file
     * @returns {Observable<void>}
     * @param file
     */
    deleteFile(file: string): Observable<void> {
        return this.http.delete<void>(this.fileEndPoint, {params: {filePath: file}}).pipe(
            finalize(() => {
                this.dataChangedEmit()
            })
        )
    }
}
